import React from "react";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import { themeType } from "../../types";

const ThemeLink = ({ theme }) => {
  return (
    <Link
      component={GatsbyLink}
      to="/media#mediaSearch"
      state={{ filter: { themes: [theme] } }}
      variant="body1"
    >
      {theme.title}
    </Link>
  );
};

ThemeLink.defaultProps = {
  theme: null,
};

ThemeLink.propTypes = {
  theme: themeType,
};

export default ThemeLink;
