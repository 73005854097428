import React from "react";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import { categoryType } from "../../types";

const CategoryLink = ({ category }) => {
  return (
    <Link
      component={GatsbyLink}
      to="/media#mediaSearch"
      state={{ filter: { category } }}
      variant="body1"
    >
      {category.title}
    </Link>
  );
};

CategoryLink.defaultProps = {
  category: null,
};

CategoryLink.propTypes = {
  category: categoryType,
};

export default CategoryLink;
