/**
 * Converts supplied string to lowercase, trims whitespace
 * @param {string} str - a string
 * @returns {string} - {@link str} lowercased and trimmed
 */
export const normalize = (str) => str.toLowerCase().trim();

/**
 * Produces the extension of a given filename
 * @param {string} filename - The filename from which to extract the extension
 * @return {string} the file extension
 */
export const getExtension = (filename = "") => {
  return filename ? filename.slice(filename.lastIndexOf(".") + 1) : "";
};

/**
 * Produces a tuple of the filename and the file extension
 * @param {string} filename - The filename from which to split
 * @return {string[]} The filename and its extension
 */
export const splitFilename = (filename = "") => {
  let name = "";
  let extension = "";
  if (filename) {
    const delimeterIndex = filename.lastIndexOf(".");
    name = filename.slice(0, delimeterIndex);
    extension = filename.slice(delimeterIndex + 1);
  }
  return [name, extension];
};

/**
 * Produces a new string with HTML elements removed from the original string
 * @param {string} str string with HTML
 * @return {string} a copy of the supplied string with HTML removed
 */
export const stripHTML = (str) => str.replace(/(<([^>]+)>)/gi, "");

/**
 * Remove english punctuation from the given string
 * @param {string} str - a string with enlgish punctuation
 * @returns {string} - {@link str} with punctuation removed
 */
export const stripPunctuation = (str) => {
  const cleanedString = str.match(/[^_\W]+/g);
  return cleanedString ? cleanedString.join(" ") : str;
};

/**
 * Replaces newline characters with the supplied replacement string
 * @param {string} str string with newline characters
 * @param {string} [replacement=""] the string with which to replace the found newlines, empty string by default
 * @return {string} a copy of the original string with the newlines replaced with replacement
 */
export const replaceNewlines = (str, replacement = "") =>
  str.replace(/\r?\n|\r/g, replacement);

/**
 * Removes HTML and replaces newlines with a replacement string from the supplied string
 * @param {string} str a string with HTML and newline chars
 * @param {string} [replacement] the replacement string with which to replace newline chars
 * @return {string} a copy of the original string with HTML removed and newlines replaced
 */
export const removeHTMLAndReplaceNewlines = (str, replacement) =>
  replaceNewlines(stripHTML(str), replacement);

/**
 * Filter callback to filter out duplicated values in the array
 * @param {*} value - the current element in the array being iterated over
 * @param {number} index - the current index of the element being iterated over
 * @param {*[]} arr - the original array being filtered
 * @returns {*[]} - A new array with only unique values
 */
export const onlyUniqueFilter = (value, index, arr) =>
  arr.indexOf(value) === index;

/**
 * Capitalizes the first letter of str and lowers the rest
 * @param {string} str - a string
 * @returns {string} - {@link str} capitalized
 */
export const capitalize = (str) => {
  const loweredStr = str.toLowerCase();
  const firstLetter = loweredStr.substring(0, 1).toUpperCase();
  const rest = loweredStr.substring(1);
  return firstLetter + rest;
};

/**
 * Removes punctuation and replaces spaces with "-"
 * @param {string} str - a string
 * @returns {string} - {@link str} with punctuation removed and spaces replaces with "-"
 */
export const kebabCase = (str) => stripPunctuation(str).split(" ").join("-");

/**
 * Removes punctuation and converts string to camelCase
 * @param {string} str - a string
 * @returns {string} - {@link str} with punctuation removed and converted to camelCase
 */
export const camelCase = (str) => {
  const strippedWords = stripPunctuation(str).split(" ");
  const words = strippedWords.map((word, index) =>
    index === 0 ? word.toLowerCase() : capitalize(word)
  );
  return words.join("");
};
