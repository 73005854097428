/* eslint-disable no-underscore-dangle */
import { isFuture } from "date-fns";
import imageUrlFor from "./image-url";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getMediaUrl(slug) {
  return `/media/${slug.current || slug}/`;
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

/**
 * Extracts a title and subtitle from a media object title
 * @param {string} mediaTitle - The title field of a Media object
 * @return {string[]} A tuple of the title and subtitle
 */
export const getHeaderText = (mediaTitle) => {
  if (!mediaTitle) {
    return ["Untitled", undefined];
  }

  const [source, title] = mediaTitle.split(" - ");
  return !title ? [source, title] : [title, source];
};

/**
 * Infers the type of the media object based on what type of media fields are present
 * @param {object} media - A Media object
 * @returns {string} A string of the media type; defaults to "video"
 */
export const inferMediaType = (media) => {
  if (media.video) {
    return "video";
  }

  if (media.file) {
    return "file";
  }

  if (media.image) {
    return "image";
  }

  return "video";
};

export const getThumbnail = (media, width = 600, height = 300) => {
  const type = inferMediaType(media);

  if (type === "file") {
    return { url: "", alt: "" };
  }

  if (type === "image") {
    return {
      url: imageUrlFor(media.image).size(width, height).url(),
      alt: media.image.alt,
    };
  }

  return {
    url: media.video && media.video.thumbnail ? media.video.thumbnail.url : "",
    alt: "",
  };
};

export const sortAlphabetically = (a, b) => {
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
};
