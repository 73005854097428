import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { makeStyles } from "@material-ui/core/styles";
import { childrenType } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(8),
  },
  icon: {
    marginBottom: theme.spacing(4),
    fontSize: theme.typography.h1.fontSize,
  },
}));

function NoContent({ text, children, icon }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {icon && <SadIcon className={classes.icon} />}
      <Typography gutterBottom={!!children} variant="h3">
        {text}
      </Typography>
      {children}
    </div>
  );
}

NoContent.defaultProps = {
  text: "There's nothing here",
  children: null,
  icon: false,
};

NoContent.propTypes = {
  text: PropTypes.string,
  children: childrenType,
  icon: PropTypes.bool,
};

export default NoContent;
