import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import VideoIcon from "@material-ui/icons/Movie";
import ImageIcon from "@material-ui/icons/Image";
import FileIcon from "@material-ui/icons/Description";
import AlbumIcon from "@material-ui/icons/PermMedia";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    fontSize: theme.spacing(8),
  },
}));

const iconTypeComponentMap = {
  video: VideoIcon,
  image: ImageIcon,
  file: FileIcon,
  album: AlbumIcon,
};

function MediaImagePlaceholder({ type, width, height, style }) {
  const classes = useStyles();
  const PlaceholderIconComponent = iconTypeComponentMap[type];

  return (
    <div className={classes.placeholder} style={{ height, width, ...style }}>
      <PlaceholderIconComponent className={classes.icon} alt="" />
    </div>
  );
}

MediaImagePlaceholder.defaultProps = {
  type: "",
  height: "auto",
  width: "auto",
  style: null,
};

MediaImagePlaceholder.propTypes = {
  type: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["auto"])]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["auto"])]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default MediaImagePlaceholder;
