import React, { isValidElement } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  inlineList: {
    display: "inline-block",
    padding: 0,
  },
  inlineListItem: {
    display: "inline-flex",
    width: "auto",
    padding: 0,
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

function InlineList({ items }) {
  if (items.length === 0) {
    return null;
  }

  const classes = useStyles();
  return (
    <List className={classes.inlineList}>
      {items.map((item, index, arr) => {
        return (
          <ListItem
            dense
            className={classes.inlineListItem}
            disableGutters
            key={item.id || index}
          >
            {isValidElement(item) ? (
              <>
                {item}
                {index < arr.length - 1 ? ", " : ""}
              </>
            ) : (
              <ListItemText primaryTypographyProps={{ variant: "body1" }}>
                {index < arr.length - 1 ? `${item.title}, ` : item.title}
              </ListItemText>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

InlineList.defaultProps = {
  items: [],
};

InlineList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ id: PropTypes.string, title: PropTypes.string }),
      PropTypes.string,
    ])
  ),
};

export default InlineList;
